<template>
  <el-row class="row" type="flex" justify="center">
    <el-col class="form-content">
      <el-form v-loading="loading" size="middle" class="student-form" :model="formData" ref="postForm" label-width="100px" label-position="right" :rules="rules" :disabled="isView">
        <el-row class="sectionLine">
          <div class="inline divSection"></div>
          <div class="inline sectionTitle">基本信息</div>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item prop="Code" label="学号">
              <el-autocomplete v-model="formData.Code" :trigger-on-focus="false" :fetch-suggestions="searchStudent" placeholder="请输入学号" clearable style="width:100%" @select="handleSelect" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Name" label="姓名">
              <el-input v-model="formData.Name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Sex" label="性别">
              <el-select v-model="formData.Sex" placeholder="请选择性别" style="width:100%">
                <el-option v-for="item in sexOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Birthday" label="出生年月">
              <el-date-picker v-model="formData.Birthday" value-format="yyyy-MM-dd" type="date" placeholder="请选择出生年月" style="width:100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Ethnic" label="民族">
              <el-input v-model="formData.Ethnic" placeholder="请输入民族"></el-input>
              <!-- <el-select v-else v-model="formData.Ethnic" placeholder="请选择民族" style="width:100%">
                <el-option v-for="item in ethnicOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="PoliticalStatus" label="政治面貌">
              <el-input v-model="formData.PoliticalStatus" placeholder="请输入政治面貌"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Phone" label="电话">
              <el-input v-model="formData.Phone" placeholder="请输入电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Email" label="邮箱">
              <el-input v-model="formData.Email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="NativePlace" label="籍贯">
              <el-input v-model="formData.NativePlace" placeholder="请输入籍贯"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="PresentAddress" label="现居地址">
              <el-input v-model="formData.PresentAddress" placeholder="请输入现居地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="sectionLine">
          <div class="inline divSection"></div>
          <div class="inline sectionTitle">学生照片</div>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="学生照片" prop="Photo">
              <el-image style="width: 120px; height: 120px" :src="formData.PhotoFullPath" v-if="isView">
                <div slot="error">
                  <!-- <i class="el-icon-picture-outline"></i> -->
                  <span>未上传照片</span>
                </div>
              </el-image>
              <picture-selector v-else v-model="formData.Photo" name="Photo" ref="Photo" remarks="120*120" @input="updateForm" :fullPath="formData.PhotoFullPath">
              </picture-selector>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row class="sectionLine">
          <div class="inline divSection"></div>
          <div class="inline sectionTitle">学生介绍</div>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="学生简介" prop="Introduction">
              <rich-text
                v-model="formData.Introduction"
                name="Introduction"
                @input="updateForm"
              ></rich-text>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <el-col class="mb-40 ml-100" :span="24">
        <el-button v-if="!isView" size="large" type="primary" @click="submitForm('postForm')">确定</el-button>
        <el-button size="large" @click="cancle">{{
          isView ? "返回" : "取消"
        }}</el-button>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import PictureSelector from "@/components/base/PictureSelector";
import { isEmail, isPhone } from "@/utils/validate";
import { getStudentByCode, getInfo, updateEntity } from "@/api/student";
import { insertStudent } from "@/api/organization";
import { getDicByCode } from "@/api/base-data";
export default {
  components: {
    PictureSelector,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    orgId: {
      type: String,
      default: "",
    },
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("请输入正确的邮箱地址"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      isConnect: false,
      formData: {},
      sexOptions: [],
      rules: {
        Name: [{ required: true, message: "请输入学生姓名", trigger: "blur" }],
        Code: [{ required: true, message: "请输入学生学号", trigger: "blur" }],
        Sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        // Phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        // Email: [{ required: true, trigger: "blur", validator: validateEmail }],
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    updateForm(fieldName, value) {
      this.formData[fieldName] = value;
    },
    handleSelect(item) {
      this.isConnect = true;
      console.log(item);
      this.formData = Object.assign({}, item.origin);
      this.formData.OrganizationId = this.orgId;
    },
    async searchStudent(query, cb) {
      this.isConnect = false;
      if (query) {
        const { Result } = await getStudentByCode({ code: query });
        if (Result != null) {
          console.log("Result: ", Result);
          const cbArr = [];
          cbArr.push({ value: Result.Name, origin: Result });
          cb(cbArr);
        } else {
          cb([]);
        }
      } else {
        cb([]);
      }
    },
    async initialize() {
      this.loading = true;
      try {
        // 新增和编辑时需要获取下拉配置
        Promise.all([this.getSex()]);
        // 查看和编辑时需要获取详情
        if (this.isView || this.isEdit) {
          const res = await getInfo({id:this.id});
          if (res.IsSuccess) {
            this.formData = Object.assign({}, res.Result);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getSex() {
      const res = await getDicByCode({ code: "2001" });
      this.sexOptions = res.Result.Details.map((item) => ({
        label: item.Name,
        value: parseInt(item.Value),
      }));
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploading = true;
          let isSuccess = false;
          let message = "服务器错误！";
          try {
            if (this.isEdit) {
              this.formData.OrganizationId = this.orgId;
              console.log(this.formData);
              const res = await updateEntity(this.formData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            } else {
              const postData = Object.assign(
                { OrganizationId: this.orgId },
                this.formData
              );
              const res = await insertStudent(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            }
            if (isSuccess) {
              this.$message.success(message || "保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isUploading = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误",
          });
          return;
        }
      });
    },
    cancle() {
      if (!this.isView) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  flex: 1;
}
.form-content {
  width: 900px;
}
.student-form {
  display: flex;
  flex-direction: column;
  padding: 40px 0 0;
  ::v-deep {
    .el-form-item {
      line-height: 40px;
    }
  }
}
</style>
