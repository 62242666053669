import request from "@/utils/request";
const controller = "/Student";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getStudentAbilityList = studentId =>
  request({
    url: `${controller}/GetStudentAbilityList`,
    method: "get",
    params: { studentId }
  });
const updateStudentAbility = (studentId, data) =>
  request({
    url: `${controller}/UpdateStudentAbility?studentId=${studentId}`,
    method: "post",
    data
  });

export { getPageList, getStudentAbilityList, updateStudentAbility };


export function getInfo(StudentId) {
  return request({
    url: `/Student/GetInfo`,
    method: "get",
    params: {
      StudentId
    }
  });
}

export function getSignRecordList(StudentId) {
  return request({
    url: `/Student/GetSignRecordList`,
    method: "get",
    params: {
      StudentId
    }
  });
}
export function getLeaveList(StudentId) {
  return request({
    url: `/Student/GetLeaveList`,
    method: "get",
    params: {
      StudentId
    }
  });
}
export function getCourseList(StudentId) {
  return request({
    url: `/Student/GetCourseList`,
    method: "get",
    params: {
      StudentId
    }
  });
}
export function getSaleList(StudentId) {
  return request({
    url: `/Student/GetSaleList`,
    method: "get",
    params: {
      StudentId
    }
  });
}
export function getDailyList(StudentId) {
  return request({
    url: `/Student/GetDailyList`,
    method: "get",
    params: {
      StudentId
    }
  });
}
